$(".js-share a").click(function (e) {
  e.preventDefault();
  const url = this.href;

  window.open(
    url,
    "",
    "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600"
  );
  return false;
});
