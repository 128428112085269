import "./scss/main.scss";

function importAll(r) {
  r.keys().forEach(r);
}
importAll(require.context("./js/components/", true, /\.js$/));

if (module.hot) {
  module.hot.accept();
}
