$(document).ready(function () {
  const ticker_list = $(".ticker");
  ticker_list.each(function(e) {
      var ticker = $(this),
      list = $(this).find('.ticker__list'),
      clone = list.clone()

      ticker.append(clone)
  })
});
